import { createApp, defineAsyncComponent } from 'vue'
import { createI18n } from 'vue-i18n'
import store from './store'
import CartApp from './CartApp.vue'
import PrintTemplateApp from './PrintTemplateApp.vue'

import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAsterisk, faFilePdf, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faAsterisk)
library.add(faFilePdf)
library.add(faCircleNotch)

const orderForms = {
  'dalaenergi-orderform': defineAsyncComponent(() => import ('./components/dalaenergi/OrderForm')),
  'ejendals-orderform': defineAsyncComponent(() => import('./components/ejendals/OrderForm')),
  'leksandskommun-orderform': defineAsyncComponent(() => import ('./components/leksandskommun/OrderForm'))
}

const i18n = createI18n({
  legacy: false,
  locale: new URLSearchParams(window.location.search).get('lang') || navigator.language,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: {
    'sv': {
      cancel: 'Avbryt',
      close: 'Stäng',
      errorToastTitle: 'Var god och rätta följande:',
      shoppingCart: {
        order: 'Beställning',
        orderPrintedProduct: 'Beställ tryckt produkt',
        orderFileForPrint: 'Beställ tryckfil',
        name: 'Varukorg',
        isEmpty: 'Varukorgen är tom',
        addToCart: 'Lägg i varukorg',
        addFileToCart: 'Lägg fil i varukorgen',
        removeFromCart: 'Ta bort',
        placeOrder: 'Lägg beställning',
        placeDirectOrder: 'Beställ direkt',
        addedToCart: 'Varan har lagts i varukorgen!',
        toCheckout: 'Till beställning',
        orderReceived: 'Tack, din beställning är nu mottagen!',
        quantity: 'Antal',
        orOrderDirect: 'Eller beställ direkt utan att lägga i varukorgen',
        approveProof: 'Jag godkänner korrekturet ovan och vill beställa:',
        printedProductBtn: 'Tryckt produkt...',
        fileForPrintBtn: 'Tryckfil...',
        noOfJobsInCart: 'antal jobb i varukorgen',
        file: 'fil'
      },
      errors: {
        anErrorOccurred: 'Ett fel inträffade',
        errorAddToCart: 'Ojdå! Ett fel inträffade när varan skulle läggas i varukorgen. Ett loggmeddelande har skickats till administratören.',
        errorOrderSave: 'Ojdå! Ett fel inträffade när beställningen skulle sparas. Ett loggmeddelande har skickats till administratören.'
      }

    },
    'en': {
      cancel: 'Cancel',
      close: 'Close',
      errorToastTitle: 'Please correct the following:',
      shoppingCart: {
        order: 'Order',
        orderPrintedProduct: 'Order printed product',
        orderFileForPrint: 'Order print file',
        name: 'Shopping cart',
        isEmpty: 'Cart is empty',
        addToCart: 'Add to cart',
        addFileToCart: 'Add file to cart',
        removeFromCart: 'Remove',
        placeOrder: 'Place order',
        placeDirectOrder: 'Place direct order',
        addedToCart: 'The product was added to the cart!',
        toCheckout: 'To checkout',
        orderReceived: 'Thanks, your order is now received!',
        quantity: 'Quantity',
        orOrderDirect: 'Or place order directly without adding to cart',
        approveProof: 'I approve of the proof above and want to order:',
        printedProductBtn: 'Printed product...',
        fileForPrintBtn: 'Print file...',
        noOfJobsInCart: 'number of jobs in the cart',
        file: 'file'
      },
      errors: {
        anErrorOccurred: 'An error occurred',
        errorAddToCart: 'Whoops! An error occurred when adding the product to the cart. A log message has been sent to the administrator.',
        errorOrderSave: 'Whoops! An error occurred when saving the order. A log message has been sent to the administrator.'
      }
    }
  }
})

const cartApp = createApp(CartApp)
const printTemplateApp = createApp(PrintTemplateApp)

for(const [name, component] of Object.entries(orderForms)) {
  cartApp.component(name, component)
  //printTemplateApp.component(name, component)
}

cartApp.component('font-awesome-icon', FontAwesomeIcon)
cartApp.config.globalProperties.TT_CTX = window.TT_CTX
cartApp.use(store)
cartApp.use(i18n)
cartApp.use(Toast, { position: POSITION.TOP_LEFT })
cartApp.mount('#cart-app')

import router from './router'

printTemplateApp.component('font-awesome-icon', FontAwesomeIcon)
printTemplateApp.config.globalProperties.TT_CTX = window.TT_CTX
printTemplateApp.use(router)
printTemplateApp.use(store)
printTemplateApp.use(i18n)
printTemplateApp.use(Toast, { position: POSITION.TOP_LEFT })
printTemplateApp.mount('#print-template-app')

